<template>
  <div id="PORTFOLIO__PAGE" class="main--gallery">

    <div class="big-titre" style="display: none;">{{ title }}</div>

    <div class="structure">

      <div class="portfolio hidden-text">
        <div class="item" v-for="(link, index) in links" :key="index">
          <a v-if="link.content" id="DOWNLOAD_FILE" :data-tag="link.content" :data-href="'https://jonasforchini.closetonone.com/app/documents/' + link.href"
            :data-name="link.href" @click.prevent="downloadFile">{{
              link.content }}</a>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import loadFont from '../scripts/loadFont.js';

export default {
  name: 'PortfolioPage',
  mixins: [loadFont],
  data() {
    return {
      title: "",
      links: [],
    }
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/pages/portfolio.json');
        this.title = response.data.title;
        this.links = response.data.links;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    },
    decodeHTML(value) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = value;
      return textarea.value;
    },
    async downloadFile(event) {
      const fileUrl = event.target.getAttribute('data-href');
      const fileName = event.target.getAttribute('data-name');
      const fileTag = event.target.getAttribute('data-tag');

      const tagger = 'Download Portfolio (' + fileTag + ')';

      this.gtag('event', tagger, {
        'event_category': 'Interaction',
        'event_label': fileName
      });

      this.gtag('event', 'Download Portfolio (All)', {
        'event_category': 'Interaction',
        'event_label': 'All Portfolio downloaded'
      });

      try {
        const response = await fetch(fileUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('There was an error downloading the file:', error);
      }
    },
    gtag() {
      window.dataLayer.push(arguments);
    },
  },
  mounted() {
    this.fetchImageData();
    document.querySelector('.menu--mobile--top').classList.add('top-important')
  },
  unmounted() {
    document.querySelector('.menu--mobile--top').classList.remove('top-important')
  }
}
</script>

<style scoped>
.big-titre {
  position: absolute;
  top: 15vh;
  left: 10vw;
  font-weight: 800;
  font-size: clamp(14px, 2vw, 35px);
  text-transform: uppercase;
}

.structure {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 80vw;
  margin-left: 10vw;
}

.portfolio {
  display: flex;
  justify-content: space-between;
}

.item {
  margin: 5vw;
}

.item a {
  display: block;
  font-weight: 800;
  font-size: clamp(15px, 2.25vw, 100px);
  text-decoration: underline;
  text-underline-offset: 2vw;
  text-decoration-color: transparent;
  transition: all 0.2s linear;
}

.item a:hover {
  text-underline-offset: 1vw;
  text-decoration-color: black;
  transition: all 0.2s linear;
}

@media only screen and (max-width: 768px) {

  .structure {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    width: 100vw;
    margin-left: 0;
  }

  .item a {
    font-size: clamp(15px, 5vw, 40px);
  }

}
</style>