<template>
  <div id="ON_THE_ROPES__PAGE" class="main--gallery" :style="{ background: bgColor }">
    <GalleryVertical :imagesData="imagesData" />
  </div>
</template>

<script>
import GalleryVertical from '../modules/gallery_vertical/GalleryVertical.vue'

export default {
  name: 'RopesPage',
  components: { GalleryVertical },
  data() {
    return {
      imagesData: {},
      bgColor: ''
    }
  },
  mounted() {
    this.fetchImageData();
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/projects/on-the-ropes.json');
        this.imagesData = response.data;
        this.bgColor = response.data.bgColor;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    }
  }
}
</script>

<style>
@media only screen and (max-width: 960px) {

  #ON_THE_ROPES__PAGE #GALLERY__VERTICAL.height-large span {
    font-size: clamp(9px, 25vw, 800px) !important;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 0.85;
  }

  #ON_THE_ROPES__PAGE #GALLERY__VERTICAL {
    margin: 0 !important;
  }

  #ON_THE_ROPES__PAGE #GALLERY__VERTICAL img {
    padding: 2vw !important;
    width: 100vw !important;
    margin: 0 !important;
    top: 0 !important;
    left: 0 !important;
    max-width: 100vw !important;
    max-height: unset !important;
    height: 100% !important;
  }

}
</style>