import Lenis from '@studio-freight/lenis';

export default {
  install: (app) => {
    // Vérifier si l'appareil est tactile
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

    if (!isTouchDevice) {
      // Si ce n'est pas un appareil tactile, initialiser Lenis
      app.config.globalProperties.$lenis = Lenis;

      setTimeout(() => {
        const APP = document.getElementById('app');
        const lenis = new Lenis({
          content: APP,
          lerp: 0.072,
          wheelMultiplier: 1,
        });

        function raf(time) {
          lenis.raf(time);

          const links = document.querySelectorAll('.STOP__SCROLL');
          links.forEach((link) => {
            link.addEventListener('click', (event) => {
              event.preventDefault();
              setTimeout(() => {
                lenis.stop();
              }, 10);
              setTimeout(() => {
                lenis.start();
              }, 100);
            });
          });

          const layer_menu_stop = document.querySelector('.stop-mobile-scroll');
          const layer_menu_start = document.querySelector('.start-mobile-scroll');

          if (layer_menu_stop) {
            layer_menu_stop.addEventListener('click', (event) => {
              event.preventDefault();
              setTimeout(() => {
                lenis.stop();
              }, 250);
            });
          }

          if (layer_menu_start) {
            layer_menu_start.addEventListener('click', (event) => {
              event.preventDefault();
              setTimeout(() => {
                lenis.start();
              }, 250);
            });
          }

          requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);

      }, 500);
    }
  },
};