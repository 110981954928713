<template>
  
  <div style="position:fixed; z-index: -1; visibility: hidden; color:transparent; pointer-events: none; opacity: 0">
    <a href="/an-apprenticeship-to-murky-depths">An Apprenticeship To Murky Depths</a>
    <a href="/spectrum">Spectrum</a>
    <a href="/salty-territories">Mangrovines : Salty Territories</a>
    <a href="/area-34">Area 34</a>
    <a href="/on-the-ropes">On The Ropes</a>

    <a href="/dummys/kancho">Kancho</a>
    <a href="/dummys/mangrovines-salty-territories">Mangrovines : Salty Territories</a>
    <a href="/dummys/brief-history-of-underwater-photography">Brief History of Underwater Photography</a>

    <a href="/">Index</a>
    <a href="/home">Home</a>
    <a href="/enter">Enter</a>

    <a href="/exhibitions">Exhibitions</a>
    <a href="/documentation">Documentation</a>
    <a href="/portfolio">Portfolio</a>
    <a href="/prints">Prints</a>
    <a href="/about">About</a>
    <a href="/contact">Contact</a>
    <a href="/social-media">Social Media</a>

  </div>


</template>