<template>
  <div id="CONTACT__PAGE" class="main--gallery">

    <div class="bio" style="z-index: 100;">

      <div class="texte--bio hidden-text">
        <span class="name-title" style="display: none;">{{ title }}</span>

        <div class="mail-section">
          <a id="MAIL" class="mail" :href="'mailto:' + mail" @click="trackMail">{{ mail }}</a>
        </div>

        <div v-for="(paragraph, index) in paragraphs" :key="index" class="text-section">
          <span class="contact_two" v-if="paragraph.text" v-html="decodeHTML(paragraph.text)"></span>
        </div>

        <div class="links-contents">
          <div class="flex_content" v-for="(link, index) in links" :key="index">
            <a v-if="link.content" :href="link.href" :target="link.blank" :data-content="link.content" @click="trackLink">{{ link.content }}</a>
          </div>
        </div>

      </div>

    </div>

    <FooterCard />

  </div>
</template>

<script>
import loadFont from '../scripts/loadFont.js';
import FooterCard from './FooterCard.vue'

export default {
  name: 'ContactPage',
  components: { FooterCard },
  mixins: [loadFont],
  data() {
    return {
      title: "",
      mail: "",
      paragraphs: [],
      links: [],
    }
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/main/contact.json');
        this.title = response.data.title;
        this.mail = response.data.mail;
        this.paragraphs = response.data.paragraphs;
        this.links = response.data.links;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    },
    decodeHTML(value) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = value;
      return textarea.value;
    },
    gtag() {
      window.dataLayer.push(arguments);
    },
    trackLink(event) {
      const link = event.target.getAttribute('data-content');

      const tagger = 'Click on ' + link + ' (Contact)';

      this.gtag('event', tagger, {
        'event_category': 'Interaction',
        'event_label': "Click on" + link
      });

      this.gtag('event', 'Click on links (Contact)', {
        'event_category': 'Interaction',
        'event_label': 'All links clicked (Contact)'
      });
    },
    trackMail() {
            this.gtag('event', 'Click on Mail (Contact)', {
                'event_category': 'Interaction',
                'event_label': 'Click on Mail (Contact)'
            });
        }
  },
  mounted() {
    this.fetchImageData();
    document.querySelector('.menu--mobile--top').classList.add('top-important')
  },
  unmounted() {
    document.querySelector('.menu--mobile--top').classList.remove('top-important')
  }
}
</script>

<style scoped>
.bio {
  display: flex;
  position: relative;
  top: 15vh;
  width: 100vw;
  padding-left: 10vw;
  height: auto;
  justify-content: flex-start;
}

.bio span {
  display: block;
  padding-top: 0;
}

.contact_two {
  font-weight: 500;
  font-size: clamp(14px, 1.3vw, 125px);
  line-height: 1.5;
}

.bio span.name-title {
  text-transform: uppercase;
  font-size: clamp(14px, 1.25vw, 125px);
  font-weight: 800;
}

.flex-content {
  margin-left: 5vw;
}

.flex_content a {
  font-size: clamp(11px, 1vw, 117px);
  font-weight: 600;
}

.text-section,
.links-contents {
  margin-left: 2vw;
}

.links-contents {
  margin-top: 2vh;
}

.bio p {
  font-size: clamp(12px, 1.1vw, 118px);
}

.mail-section {
  padding: 5vw 2vw 2vw;
  font-weight: 800;
  font-size: clamp(22px, 1.5vw, 135px);
}

.mail-section a {
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: 1.5vh;
  transition: all 0.55s ease;
}

.mail-section a:hover {
  text-decoration-color: black;
  transition: all 0.55s ease;
}

@media only screen and (max-width: 768px) {
  .bio {
    padding-left: 4vw;
  }

  .mail-section {
    font-size: clamp(20px, 5.5vw, 135px);
    margin-bottom: 2vh;
  }
}
</style>