<template>
  <div id="AREA_34__PAGE" class="main--gallery" :style="{ background: bgColor }">
    <GalleryVertical :imagesData="imagesData" />
  </div>
</template>

<script>
import GalleryVertical from '../modules/gallery_vertical/GalleryVertical.vue'

export default {
  name: 'AreaPage',
  components: { GalleryVertical },
  data() {
    return {
      imagesData: {},
      bgColor: ''
    }
  },
  created() {
    this.fetchImageData();
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/projects/area-34.json');
        this.imagesData = response.data;
        this.bgColor = response.data.bgColor;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    }
  }
}
</script>

<style>
@media only screen and (max-width: 960px) {

  #AREA_34__PAGE #GALLERY__VERTICAL.height-large span {
    font-size: clamp(9px, 18vw, 800px) !important;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1;
  }

}
</style>