<template>
  <div id="HOME__PAGE" class="main--gallery">

    <div class="reveal--homepage"></div>

    <div style="display: none;">
      <img src="https://jonasforchini.closetonone.com/app/img/home/jonas-forchini_homepage.jpg"
        data-src-load="https://jonasforchini.closetonone.com/app/img/home/jonas-forchini_homepage.jpg"
        alt="Jonàs Forchini - Portfolio - Homepage- Underwater Photography - Bio - Biography - Info - À Propos" />
    </div>

    <ListMobile />

  </div>
</template>

<script>
import Transition from '../../scripts/Transition.js';
import ListMobile from './ListMobile.vue';

export default {
  name: 'HomePage',
  components: { ListMobile },
  mixins: [Transition],
  methods: {
    loadImage(img) {
      return new Promise((resolve, reject) => {
        if (img.complete) {
          resolve(img);
        } else {
          img.onload = () => resolve(img);
          img.onerror = () => reject(new Error('Failed to load image: ' + img.dataset.srcLoad));
        }
        img.src = img.dataset.srcLoad;
      });
    },
    loadGallery() {
      const gallery = document.querySelector('.main--gallery');
      const images = document.querySelectorAll('.load__image');
      const loader = document.querySelector('.loader--gallery');

      const imagePromises = Array.from(images).map(img => this.loadImage(img));

      Promise.all(imagePromises)
        .then(() => {
          gallery.classList.remove('hidden-gallery');
          loader.style.opacity = '0';
        })
        .catch(error => {
          console.error('An error occurred while loading images:', error);
        });
    },
  },
  mounted() {
    document.querySelector('.block--menu').classList.add('hide')
    document.querySelector('.mobile--title').style.background = "transparent"
    document.querySelector('.mobile--title').style.pointerEvents = "none"
    document.querySelector('.menu-icon').style.background = "transparent"
    document.querySelector('.menu--mobile--top').classList.add('top-important--home')
    document.querySelector('.burger-menu-container').classList.add('opacity--menu--home')

    setTimeout(() => {
      document.querySelector('.main--menu').style.opacity = "1"
      document.querySelector('.menu--mobile').style.opacity = "1"
    }, 100)

    document.querySelector('.main--gallery').classList.add('hidden-gallery')

    const loaderG = document.querySelector('.loader--gallery')
    loaderG.classList.remove('hide')
    loaderG.style.display = "flex"
    loaderG.style.opacity = "1"

    this.loadGallery()

  },
  beforeUnmount() {
    document.querySelector('.block--menu').classList.remove('hide')
    document.querySelector('.menu--mobile--top').classList.remove('top-important--home')
    document.querySelector('.mobile--title').style.pointerEvents = ""
    document.querySelector('.mobile--title').style.background = ""
    document.querySelector('.menu-icon').style.background = ""
    document.querySelector('.burger-menu-container').classList.remove('opacity--menu--home')
    document.querySelector('.list--mobile').classList.remove('delay')
  }
}
</script>

<style>
@import url('./styles.css');
@import url('./styles__animations.css');
@import url('./styles__responsive.css');
</style>