<template>
  <div id="PRINTS__PAGE" class="main--gallery">

    <div class="card_about">

      <div id="BIO" class="bio">

        <div class="image_about">
          <img class="load__image"
          src="https://jonasforchini.closetonone.com/app/img/prints/jonas-forchini_prints.jpg"
          data-src-load="https://jonasforchini.closetonone.com/app/img/prints/jonas-forchini_prints.jpg"
          src-placeholder="https://jonasforchini.closetonone.com/app/img/prints/lazy/jonas-forchini_prints.jpg"
            alt="Jonàs Forchini - Prints - Underwater Photography - Bio - Biography - Info - À Propos" />
        </div>

        <div class="texte--bio about">

          <div v-for="(paragraph, index) in paragraphs" :key="index">
            <p class="paraph" v-if="paragraph.text" v-html="decodeHTML(paragraph.text)"></p>
          </div>

        </div>

        <div class="mail-section">
          <a id="MAIL" class="mail" :href="'mailto:' + mail">{{ mail }}</a>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import loadFont from '../scripts/loadFont.js'

export default {
  name: 'PrintsPage',
  mixins: [loadFont],
  data() {
    return {
      mail: "",
      paragraphs: [],
    }
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/pages/prints.json');
        this.paragraphs = response.data.paragraphs;
        this.mail = response.data.mail;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    },
    decodeHTML(value) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = value;
      return textarea.value;
    },
    loadImage(img) {
      return new Promise((resolve, reject) => {
        if (img.complete) {
          resolve(img);
        } else {
          img.onload = () => resolve(img);
          img.onerror = () => reject(new Error('Failed to load image: ' + img.dataset.srcLoad));
        }
        img.src = img.dataset.srcLoad;
      });
    },
    loadGallery() {
      const gallery = document.querySelector('.main--gallery');
      const images = document.querySelectorAll('.load__image');
      const loader = document.querySelector('.loader--gallery');

      const imagePromises = Array.from(images).map(img => this.loadImage(img));

      Promise.all(imagePromises)
        .then(() => {
          gallery.classList.remove('hidden-gallery');
          loader.style.opacity = '0';
        })
        .catch(error => {
          console.error('An error occurred while loading images:', error);
        });
    },
  },
  mounted() {
    this.fetchImageData();

    document.querySelector('.main--gallery').classList.add('hidden-gallery')

    const loaderG = document.querySelector('.loader--gallery')
    loaderG.classList.remove('hide')
    loaderG.style.display = "flex"
    loaderG.style.opacity = "1"

    setTimeout(() => {
      this.loadGallery()
    }, 800)

    document.querySelector('.menu--mobile--top').classList.add('top-important')

    document.querySelector('.main--gallery').style.marginTop = "0"
    document.body.style.maxHeight = "100%"
    document.body.style.overflowY = "hidden"
  },
  unmounted() {
    document.querySelector('.menu--mobile--top').classList.remove('top-important')
    document.body.style.maxHeight = ""
    document.body.style.overflowY = ""
    document.querySelector('.main--gallery').style.marginTop = ""
  }
}
</script>

<style scoped>
.card_about {
  display: flex;
  flex-flow: column;
}

.bio {
  display: flex;
  position: relative;
  flex-flow: row;
  top: unset;
  margin-top: 20vh;
  margin-bottom: 0vh;
  width: 80vw;
  padding-left: 10vw;
  height: auto;
  justify-content: center;
}

.bio .image_about {
  margin-right: 0vw;
}

.bio span.name-title {
  font-weight: 600;
}

.bio span {
  display: block;
  padding-top: 0.5vh;
}

.bio p {
  font-size: clamp(10px, 1vw, 100px);
  line-height: clamp(12px, 1.5vw, 200px);
  margin: 0;
}

.texte--bio {
  width: 40vw;
  padding-top: 0;
  padding-bottom: 0.5vh;
  display: flex;
  margin-left: 2vw;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
}

.texte--bio.about p {
  text-align: justify;
}

.image_about {
  width: auto;
  display: flex;
  justify-self: center;
  justify-content: center;
}

.image_about img {
  width: fit-content;
  max-width: 60vw;
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 0;
  object-position: right;
  max-height: 80vh;
}

.flex_content {
  display: flex;
  flex-flow: column;
}

.flex_content a {
  padding: 0.1vw 0.1vw;
  cursor: pointer;
  font-weight: 800;
  font-size: clamp(9px, 1vw, 120px);
}

.flex_content a:hover {
  text-decoration: underline;
}

.links-contents {
  margin-top: 2vw;
  color: black;
}


.mail-section {
  padding: 0;
  font-weight: 800;
  font-size: clamp(9px, 1.2vw, 350px);
}

.mail-section a {
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: 0.75vh;
  transition: all 0.55s ease;
}

.mail-section a:hover {
  text-decoration-color: black;
  transition: all 0.55s ease;
}

@media only screen and (max-width: 768px) {
  #PRINTS__PAGE {
    margin-top: 0;
  }

  .bio {
    display: flex;
    position: relative;
    top: -16vh;
    width: 100vw;
    flex-flow: column;
    padding-left: 0;
    align-items: center;
    height: auto;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 25vh;
  }

  .image_about img {
    max-width: unset;
    object-position: center;
    margin-bottom: 0vh;
    width: 100vw;
    max-height: 65vh;
  }

  .texte--bio {
    padding-bottom: 20vh;
    width: 90vw;
    margin-left: 0vw;
  }

  .bio p {
    font-size: clamp(10px, 1.8vh, 100px);
    line-height: clamp(12px, 2vh, 200px);
    margin: 0;
  }
}
</style>