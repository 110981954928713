import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import axios from 'axios'

import LenisPlugin from './lenis.js'
import gsap from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const VueApp = createApp(App)

VueApp.config.globalProperties.$axios = axios
VueApp.config.globalProperties.$gsap = gsap
VueApp.config.globalProperties.$scrollTrigger = ScrollTrigger

VueApp.use(router)
VueApp.use(LenisPlugin)
  
VueApp.mount('#app')
