<template>
  <div id="ABOUT__PAGE" class="main--gallery">

    <div class="card_about">

      <div id="BIO" class="bio">

        <div class="image_about">
          <img class="mobile__img load__image" src="https://jonasforchini.closetonone.com/app/img/about/jonas-forchini_about.jpg"
            data-src-load="https://jonasforchini.closetonone.com/app/img/about/jonas-forchini_about.jpg"
            alt="Jonàs Forchini - About - Underwater Photography - Bio - Biography - Info - À Propos" />
        </div>

        <div class="texte--bio about hidden-text">

          <span v-if="title" v-html="decodeHTML(title)" class="name-title"></span>

          <div v-for="(paragraph, index) in paragraphs" :key="index">
            <p class="paraph" v-if="paragraph.text" v-html="decodeHTML(paragraph.text)"></p>
          </div>

          <div v-if="Array.isArray(links) && links.length" class="links-contents">
            <div class="flex_content" v-for="(link, index) in links" :key="index">
              <a v-if="link.content" :href="link.href" :target="link.blank">{{ link.content }}</a>
            </div>
          </div>

        </div>

      </div>

      <div id="CV" class="hidden-text">

        <div v-for="(item, index) in listing" :key="index" class="listing_exhibitions">

          <label v-html="decodeHTML(item.name)"></label>

          <div v-for="(group, groupIndex) in item.groups" :key="groupIndex" class="line">
            <div v-for="(line, lineIndex) in group.lines" :key="lineIndex">
              <span v-if="line.line" v-html="decodeHTML(line.line)"></span>
            </div>
          </div>

        </div>

      </div>

    </div>

    <FooterCard />

  </div>
</template>

<script>
import loadFont from '../scripts/loadFont.js';

import FooterCard from './FooterCard.vue'

export default {
  name: 'AboutPage',
  components: { FooterCard },
  mixins: [loadFont],
  data() {
    return {
      title: "",
      paragraphs: [],
      links: [],
      listing: [],
      bioHeight: 0
    }
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/main/about.json')
        this.title = response.data.title
        this.paragraphs = response.data.paragraphs
        this.links = response.data.links
        this.listing = response.data.listing
      } catch (error) {
        console.error('Error fetching images data:', error)
      }
    },
    decodeHTML(value) {
      const textarea = document.createElement('textarea')
      textarea.innerHTML = value
      return textarea.value
    },
    updateBioHeight() {
      this.$nextTick(() => {
        const bioElement = this.$el.querySelector('.texte--bio');
        if (bioElement) {
          this.bioHeight = bioElement.offsetHeight;
          this.$el.querySelector('.image_about').style.height = this.bioHeight + 'px';
        }
      });
    },
    loadImage(img) {
      return new Promise((resolve, reject) => {
        if (img.complete) {
          resolve(img);
        } else {
          img.onload = () => resolve(img);
          img.onerror = () => reject(new Error('Failed to load image: ' + img.dataset.srcLoad));
        }
        img.src = img.dataset.srcLoad;
      });
    },
    loadGallery() {
      const gallery = document.querySelector('.main--gallery');
      const images = document.querySelectorAll('.load__image');
      const loader = document.querySelector('.loader--gallery');

      const imagePromises = Array.from(images).map(img => this.loadImage(img));

      Promise.all(imagePromises)
        .then(() => {
          gallery.classList.remove('hidden-gallery');
          loader.style.opacity = '0';
        })
        .catch(error => {
          console.error('An error occurred while loading images:', error);
        });
    },
  },
  mounted() {
    this.fetchImageData().then(() => {
      this.updateBioHeight();
    }).catch((error) => {
      console.error('Erreur lors du chargement des données :', error);
    });

    window.addEventListener('resize', this.updateBioHeight);

    document.querySelector('.main--gallery').classList.add('hidden-gallery')

    const loaderG = document.querySelector('.loader--gallery')
    loaderG.classList.remove('hide')
    loaderG.style.display = "flex"
    loaderG.style.opacity = "1"

    setTimeout(() => {
      this.loadGallery()
    }, 800)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateBioHeight);
  }
}
</script>

<style scoped>
.mobile__img {
  display: none;
}

.card_about {
  display: flex;
  flex-flow: column;
}

.bio {
  display: flex;
  position: relative;
  top: unset;
  margin-top: 15vh;
  margin-bottom: 15vh;
  width: 100vw;
  padding-left: 0;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
}

.bio .image_about {
  margin-right: 0vw;
}

.bio span.name-title {
  font-weight: 600;
  font-size: clamp(14px, 0.8vw, 150px);
}

.bio span {
  display: block;
  padding-top: 0.5vh;
}

.bio p {
  font-size: clamp(10px, 1.1vw, 125px);
  line-height: clamp(11px, 1.5vw, 200px);
}

.texte--bio {
  width: 30%;
  max-width: unset;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  position: relative;
  padding-left: 1.5vw;
  padding-right: 5vw;
}

.texte--bio.about div {
  margin-bottom: 1.5vh;
}

.texte--bio.about div:last-child {
  margin-bottom: 0vh;
}

.texte--bio.about p {
  text-align: justify;
  margin: 0;
}

.image_about {
  width: auto;
  height: -moz-fit-content;
  display: flex;
  height: fit-content;
  justify-self: center;
  justify-content: flex-start;
}

.image_about {
  width: 70vw;
  height: 70vh;
  background: url(https://jonasforchini.closetonone.com/app/img/about/jonas-forchini_about.jpg);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
}

.image_about img {
  max-width: unset;
  height: 100%;
  -o-object-fit: contain;
  object-fit: fill;
  padding: 0;
  -o-object-position: top right;
  object-position: top right;
  max-height: 70vh;
}

.flex_content {
  display: flex;
  flex-flow: column;
}

.flex_content a {
  padding: 0.1vw 0.1vw;
  cursor: pointer;
  font-weight: 800;
  font-size: clamp(9px, 1vw, 120px);
}

.flex_content a:hover {
  text-decoration: underline;
}

.links-contents {
  margin-top: 2vw;
  color: black;
}

#CV {
  max-width: 95vw;
  padding-right: 5vw;
  padding-left: 10vw;
  margin: auto;
  -moz-columns: 3 auto;
  columns: 3 auto;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  margin-left: 4.5vw;
  padding-bottom: 10vh;
}

.listing_exhibitions {
  margin: 0 0 1vw;
  display: inline-block;
  width: 18vw;
  text-align: left;
  font-weight: bold;
}

.listing_exhibitions label {
  font-size: clamp(10px, 0.8vw, 100px);
  font-weight: 800;
  text-transform: uppercase;
}

.listing_exhibitions .line {
  margin-top: 1vw;
  line-height: 1.4;
}

.listing_exhibitions .line:last-child {
  margin-bottom: 1.5vw;
}

.listing_exhibitions .line div:nth-child(1) {
  font-size: clamp(10px, 0.8vw, 100px);
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: clamp(2px, 0.7vw, 4px);
  text-transform: uppercase
}

.listing_exhibitions .line div:nth-child(2) {
  font-size: clamp(10px, 0.65vw, 30px);
  font-weight: 400;
  text-transform: none;
  margin-top: -0.3vw;
}

.listing_exhibitions .line div {
  font-size: clamp(10px, 0.7vw, 30px);
  font-weight: 100;
}

@media only screen and (max-width: 768px) {
  #ABOUT__PAGE {
    margin-top: 7vh;
  }

  .bio {
    display: flex;
    position: relative;
    top: 0;
    width: 100vw;
    margin-top: 0;
    margin-bottom: 0;
    flex-flow: column;
    padding-left: 0;
    align-items: center;
    height: auto;
    justify-content: center;
  }

  .image_about {
    width: 100vw;
    height: auto !important;
    background: none !important;
  }

  .mobile__img {
    display: block !important;
  }

  .image_about img {
    -o-object-position: center;
    object-position: center;
    margin-bottom: 3vh;
    width: 100vw;
    max-height: 100%;
  }

  .texte--bio {
    padding-bottom: 20vh;
    width: 90vw;
    padding: 0 0 7vh 0;
  }

  .bio p {
    font-size: clamp(10px, 2vh, 125px);
    line-height: clamp(11px, 2.8vh, 200px);
  }

  #CV {
    width: 90vw;
    margin: 0 5vw;
    padding: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
  }

  .listing_exhibitions {
    width: 90vw;
  }

  .listing_exhibitions:last-child {
    margin-bottom: 15vh;
  }

  .listing_exhibitions label {
    font-size: clamp(10px, 1.8vh, 100px);
    display: block;
    margin-top: 5vh;
    font-weight: 900;
    margin-bottom: 2vh;
    padding-bottom: 1vh;
    border-bottom: 1px solid rgba(0, 0, 0, 0.37);
  }

  .listing_exhibitions .line {
    margin-bottom: 2.4vh;
  }

  .listing_exhibitions .line div:nth-child(1) {
    font-size: clamp(10px, 1.5vh, 100px);
    line-height: 1.3;
    font-weight: 600;
    text-transform: uppercase;
  }

  .listing_exhibitions .line div:nth-child(2) {
    font-size: clamp(10px, 1.4vh, 100px);
    line-height: 1.3;
    text-transform: none;
    margin-top: -0.3vh;
  }

  .listing_exhibitions .line div:nth-child(3) {
    font-size: clamp(10px, 1.2vh, 100px);
    line-height: 1.3;
  }

}
</style>