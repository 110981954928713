<template>
  <div id="DOCUMENTATION__PAGE" class="main--gallery">

    <GalleryVertical :imagesData="imagesData" />
  </div>
</template>

<script>
import GalleryVertical from './modules/gallery_vertical/GalleryVertical.vue'

export default {
  name: 'DocumentationPage',
  components: { GalleryVertical },
  data() {
    return {
      titre: "",
      imagesData: {}
    }
  },
  mounted() {
    this.fetchImageData();
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/projects/documentation.json');
        this.imagesData = response.data;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    },
    decodeHTML(value) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = value;
      return textarea.value;
    },
  }
}
</script>

<style scoped>
.titre--documentation {
  font-weight: 800;
  text-transform: uppercase;
  position: fixed;
  bottom: 6vh;
  left: 4vw;
  font-size: clamp(20px, 5vw, 150px);
}
</style>