export default {
    data() {
        return {
            time: 400,
        }
    },
    methods: {
        resetMenu() {
            var lists = document.querySelectorAll('.list');
            var white = document.querySelector('.block--menu');
            var background = document.querySelector('.block--background');

            lists.forEach((list) => {
                list.style.opacity = '0';
                list.style.pointerEvents = 'none';
                list.classList.remove('selected')
            })

            background.classList.remove('hidden');
            white.style.opacity = '0';
            white.style.pointerEvents = 'none';
        },
        transitionPage() {
            this.resetMenu()
            const menuBurger = document.querySelector('.burger-menu-container');
            const blockT = document.querySelector('.block--transition');
            const blockMenu = document.querySelector('.block--menu');
            const mainGallery = document.querySelector('.main--gallery')
            const galleries = document.querySelectorAll('.gallery')

            document.querySelector('.block--background').classList.add('hidden')

            blockT.classList.remove('hidden')
            blockT.classList.add('showing')
            blockMenu.classList.add('showing')

            document.querySelector('.menu--mobile--top').style.top = 0

            setTimeout(() => {
                mainGallery.classList.remove('showing')
                mainGallery.classList.add('hidden')
            }, 50)

            setTimeout(() => {
                if (galleries) {
                    galleries.forEach(gallery => {
                        gallery.style.display = 'none';
                    });
                }
            }, 200)

            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'auto',
                });
            }, 200)

            setTimeout(() => {
                blockT.classList.remove('showing')
                blockMenu.classList.remove('showing')

                blockT.classList.add('hidden')
                menuBurger.classList.remove('open');
                document.documentElement.classList.remove('no-scroll');
            }, 450)

            setTimeout(() => {
                document.querySelector('.burger-menu-toggle').classList.remove('open')
            }, 1000)

            setTimeout(() => {
                mainGallery.classList.remove('hidden')
                mainGallery.classList.add('showing')
                document.querySelector('.menu--mobile--top').classList.remove('top-important')
            }, 1200)
        },
        transitionHome() {
            const menuBurger = document.querySelector('.burger-menu-container');
            const blockMenu = document.querySelector('.block--menu');
            const mainGallery = document.querySelector('.main--gallery')
            const galleries = document.querySelectorAll('.gallery')

            blockMenu.classList.add('showing')

            setTimeout(() => {
                mainGallery.classList.remove('showing')
                mainGallery.classList.add('hidden')
            }, 50)

            setTimeout(() => {
                if (galleries) {
                    galleries.forEach(gallery => {
                        gallery.style.display = 'none';
                    });
                }
            }, 200)

            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'auto',
                });
            }, 200)

            setTimeout(() => {
                blockMenu.classList.remove('showing')
                menuBurger.classList.remove('open');
                document.documentElement.classList.remove('no-scroll');
            }, 1200)

            setTimeout(() => {
                mainGallery.classList.remove('hidden')
                mainGallery.classList.add('showing')
            }, 1200)
        },
        goToHomeFromEnter() {
            this.resetMenu()
            setTimeout(() => {
                this.$router.push({ name: 'HomePage' });
            }, 150)
        },
        goToHome() {
            this.transitionPage();
            this.resetMenu()
            setTimeout(() => {
                this.$router.push({ name: 'HomePage' });
            }, this.time)
        },
        goToMurky() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'MurkyPage' });
            }, this.time)
        },
        goToSpectrum() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'SpectrumPage' });
            }, this.time)
        },
        goToSalty() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'SaltyPage' });
            }, this.time)
        },
        goToArea() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'AreaPage' });
            }, this.time)
        },
        goToRopes() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'RopesPage' });
            }, this.time)
        },

        // DUMMYS
        goToUnderwater() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'UnderwaterPage' });
            }, this.time)
        },
        goToTerritories() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'TerritoriesPage' });
            }, this.time)
        },
        goToKancho() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'KanchoPage' });
            }, this.time)
        },
        goToExpoMurky() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'ExhibitionsMurkyPage' });
            }, this.time)
        },
        goToExpoGreen() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'ExhibitionsGreenPage' });
            }, this.time)
        },
        goToExhibitions() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'ExhibitionsPage' });
            }, this.time)
        },
        goToAbout() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'AboutPage' });
            }, this.time)
        },
        goToContact() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'ContactPage' });
            }, this.time)
        },
        goToSocialMedia() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'SocialPage' });
            }, this.time)
        },
        goToDocumentation() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'DocumentationPage' });
            }, this.time)
        },
        goToPortfolio() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'PortfolioPage' });
            }, this.time)
        },
        goToPrints() {
            this.transitionPage();
            setTimeout(() => {
                this.$router.push({ name: 'PrintsPage' });
            }, this.time)
        },
    }
}