<template>
  <div class="main--menu hidden-text">
    <div class="header">
      <div id="NAMING" class="name link goto" @click="hideSub()"><a @click="goToHome()">Jonàs Forchini</a></div>

      <div class="menu--home link">
        <div class="link"><a class="projects2" @click="listProjects()">Projects</a></div>
        <div class="list projects">
          <div class="sub--menu">Practice From the Bottom</div>
          <span class="link goto" @click="hideSub()">
            <a @click="goToMurky()">An Apprenticeship to Murky Depths</a></span>
          <span class="link goto" @click="hideSub()">
            <a @click="goToSpectrum()">Spectrum</a></span>
          <div class="sub--menu">Mangrovines</div>
          <span class="link goto" @click="hideSub()"><a @click="goToSalty()">Salty Territories</a></span>
          <span class="link goto" @click="hideSub()"><a @click="goToArea()">Area 34</a></span>
          <span class="link goto" @click="hideSub()"><a @click="goToRopes()">On The Ropes</a></span>
        </div>
      </div>

      <div class="menu--home link">
        <div class="link"><a class="dummys2" @click="listDummys()">Dummys</a></div>
        <div class="list dummys">
          <span class="link goto" @click="hideSub()">
            <a @click="goToUnderwater()">Brief history of underwater photography</a></span>
          <span class="link goto" @click="hideSub()" style="margin-top: 1vh;"><a @click="goToTerritories()">Mangrovines
              : Salty Territories</a></span>
          <span class="link goto" @click="hideSub()" style="margin-top: 1vh;"><a @click="goToKancho()">Kancho</a></span>
        </div>
      </div>

      <!-- <div class="menu--home link" style="width:20%">
        <div class="link goto"><a @click="listExpos()">Exhibitions</a></div>
        <div class="list expos">
          <span class="link goto" @click="hideSub()">
            <a @click="goToExpoMurky()">An Apprenticeship to Murky Depths</a></span>
          <span class="link goto" @click="hideSub()" style="margin-top: 1vh;"><a @click="goToExpoGreen()">Green Capsule</a></span>
        </div>
      </div> -->


      <div class="menu--home last-head link">
        <div class="link goto"><a @click="goToExhibitions()">Exhibitions</a></div>
      </div>

    </div>

    <div class="header--right">
      <div class="link goto"><a @click="goToAbout()">About</a></div>
      <div class="link goto"><a @click="goToContact()">Contact</a></div>
      <div class="link goto"><a @click="goToSocialMedia()">Social Media</a></div>
    </div>

    <div class="header--left">
      <div class="link goto"><a @click="goToPrints()">Prints</a></div>
      <div class="link goto"><a @click="goToPortfolio()">Portfolio</a></div>
      <div class="link goto"><a @click="goToDocumentation()">Documentation</a></div>
    </div>

    <div v-if="isHomePage" class="block--menu" style="visibility: hidden;" @click="hideSub()"></div>
    <div v-if="!isHomePage" class="block--menu" @click="hideSub()"></div>

    <div class="block--transition hidden"></div>
  </div>
</template>

<script>
import Transition from '../scripts/Transition.js';
import loadFont from '../scripts/loadFont.js'

export default {
  name: 'MenuPage',
  mixins: [Transition, loadFont],
  computed: {
    isHomePage() {
      return this.$route.path === '/' || this.$route.path === '/enter';
    }
  },
  methods: {
    listProjects() {
      var projectsList = document.querySelector('.projects');
      projectsList.style.opacity = (projectsList.style.opacity === '0' || projectsList.style.opacity === '') ? '1' : '0';
      projectsList.style.pointerEvents = (projectsList.style.pointerEvents === 'none' || projectsList.style.pointerEvents === '') ? 'auto' : 'none';
      projectsList.classList.toggle('selected')

      var whiteBlock = document.querySelector('.block--menu');
      whiteBlock.style.opacity = (whiteBlock.style.opacity === '0' || whiteBlock.style.opacity === '') ? '1' : '0';
      whiteBlock.style.pointerEvents = 'auto';

      if (document.querySelector('.dummys').classList.contains('selected') ||
        document.querySelector('.projects').classList.contains('selected')) {
        document.querySelector('.block--menu').style.opacity = '1';
        document.querySelector('.block--menu').style.pointerEvents = 'auto';
      } else {
        document.querySelector('.block--menu').style.opacity = '';
        document.querySelector('.block--menu').style.pointerEvents = 'none';
      }

    },
    listDummys() {
      var projectsList = document.querySelector('.dummys');
      projectsList.style.opacity = (projectsList.style.opacity === '0' || projectsList.style.opacity === '') ? '1' : '0';
      projectsList.style.pointerEvents = (projectsList.style.pointerEvents === 'none' || projectsList.style.pointerEvents === '') ? 'auto' : 'none';
      projectsList.classList.toggle('selected')

      var whiteBlock = document.querySelector('.block--menu');
      whiteBlock.style.opacity = (whiteBlock.style.opacity === '0' || whiteBlock.style.opacity === '') ? '1' : '0';
      whiteBlock.style.pointerEvents = 'auto';

      if (document.querySelector('.dummys').classList.contains('selected') ||
        document.querySelector('.projects').classList.contains('selected')) {
        document.querySelector('.block--menu').style.opacity = '1';
        document.querySelector('.block--menu').style.pointerEvents = 'auto';
      } else {
        document.querySelector('.block--menu').style.opacity = '';
        document.querySelector('.block--menu').style.pointerEvents = 'none';
      }
    },
    listExpos() {
      var projectsList = document.querySelector('.expos');
      projectsList.style.opacity = (projectsList.style.opacity === '0' || projectsList.style.opacity === '') ? '1' : '0';
      projectsList.style.pointerEvents = (projectsList.style.pointerEvents === 'none' || projectsList.style.pointerEvents === '') ? 'auto' : 'none';
      projectsList.classList.toggle('selected')

      var whiteBlock = document.querySelector('.block--menu');
      whiteBlock.style.opacity = (whiteBlock.style.opacity === '0' || whiteBlock.style.opacity === '') ? '1' : '0';
      whiteBlock.style.pointerEvents = 'auto';

      if (document.querySelector('.expos').classList.contains('selected') ||
        document.querySelector('.dummys').classList.contains('selected') ||
        document.querySelector('.projects').classList.contains('selected')) {
        document.querySelector('.block--menu').style.opacity = '1';
        document.querySelector('.block--menu').style.pointerEvents = 'auto';
      } else {
        document.querySelector('.block--menu').style.opacity = '';
        document.querySelector('.block--menu').style.pointerEvents = 'none';
      }
    },
    hideSub() {
      var projectsList = document.querySelector('.projects');
      projectsList.style.opacity = '0';
      projectsList.style.pointerEvents = 'none';

      var projectsList2 = document.querySelector('.dummys');
      projectsList2.style.opacity = '0';
      projectsList2.style.pointerEvents = 'none';

      // var projectsList3 = document.querySelector('.expos');
      // projectsList3.style.opacity = '0';
      // projectsList3.style.pointerEvents = 'none';

      projectsList.classList.remove('selected');
      projectsList2.classList.remove('selected');
      // projectsList3.classList.remove('selected');

      var whiteBlock = document.querySelector('.block--menu');
      whiteBlock.style.opacity = '0';
      whiteBlock.style.pointerEvents = 'none';
    },
    triggerAnimation() {
      // gsap.to(this.$refs.elementToAnimate, { opacity: 0, duration: 1 });
      this.$gsap.set(".projects2, .dummys2", { y: '-5vh', scale: 1, opacity: 0 })

      const tl = this.$gsap.timeline({ delay: 8, ease: "sine.out" });

      tl
        .to(".projects2", {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "bounce"
        },)
        .to(".dummys2", {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "bounce"
        }, "+=1")
    }
  }
}
</script>

<style>
.main--menu {
  position: fixed;
  z-index: 200000;
  user-select: none;
  width: 100vw;
  transition: opacity 1.75s ease !important;
}

.menu--mobile {
  transition: opacity 1.75s ease !important;
}
</style>