<template>
  <div id="UNDERWATER__PAGE" class="main--gallery">

    <GalleryDummySliderTwo :imagesData="imagesData" />

  </div>
</template>

<script>
import GalleryDummySliderTwo from '../modules/gallery_dummy/GalleryDummySliderTwo.vue'

export default {
  name: 'UnderwaterPage',
  components: { GalleryDummySliderTwo },
  data() {
    return {
      imagesData: {}
    }
  },
  created() {
    this.fetchImageData();
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/dummys/brief-history-of-underwater-photography.json');
        this.imagesData = response.data;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    }
  }
}
</script>