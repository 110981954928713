<template>
  <div class="menu--mobile" style="user-select: none;">

    <div class="menu--mobile--top" :style="{ top: menuTop }">

      <div id="NAMING" class="mobile--title hidden-text" @click="goToHome()">Jonàs Forchini</div>

      <div @click="displayMenu" class="menu-icon burger-menu-toggle">
        <span class="menu-icon__line"></span>
        <span class="menu-icon__line"></span>
        <span class="menu-icon__line"></span>
      </div>

    </div>

    <div v-if="menuVisible" id="LAYER__MENU" class="burger-menu-container start-mobile-scroll">

      <div class="burger-menu">
        <ul class="main-navigation hidden-text">

          <div>
            <li style="padding-top: 15vh;">
              <span class="titre--1">Projects</span>

              <span class="titre--2">Practice from the bottom</span>
              <span class="space-top"><a @click="goToMurky()">An Apprenticeship to Murky Depths</a></span>
              <span><a @click="goToSpectrum()">Spectrum</a></span>

              <span class="titre--2">Mangrovines</span>
              <span class="space-top"><a @click="goToSalty()">Salty Territories</a></span>
              <span><a @click="goToArea()">Area 34</a></span>
              <span><a @click="goToRopes()">On The Ropes</a></span>
            </li>
            <div style="margin-top: 5vh">
              <li>
                <span class="titre--1">Dummys</span>

                <span class="space-top"><a @click="goToUnderwater()">Brief history of underwater
                    photography</a></span>
                <span><a @click="goToTerritories()">Mangrovines : Salty Territories</a></span>
                <span><a @click="goToKancho()">Kancho</a></span>
              </li>
            </div>

          </div>

          <div>
            <div>
              <li>
                <span class="titre--3" @click="goToExhibitions()">Exhibitions</span>
                <!-- <span class="space-top"><a @click="goToExpoMurky()">An Apprenticeship to Murky Depths</a></span>
                <span><a @click="goToExpoGreen()">Green Capsule</a></span> -->
              </li>
            </div>
            <div>
              <li>
                <span class="titre--3"><a @click="goToPrints()">Prints</a></span>
                <span class="titre--3"><a @click="goToPortfolio()">Portfolio</a></span>
                <span class="titre--3"><a @click="goToDocumentation()">Documentation</a></span>
              </li>
            </div>
            <div>
              <li>
                <span class="titre--3"><a @click="goToAbout()">About</a></span>
                <span class="titre--3"><a @click="goToContact()">Contact</a></span>
                <span class="titre--3"><a @click="goToSocialMedia()">Social Media</a></span>
              </li>

            </div>
          </div>

        </ul>
      </div>
      <div class="overlay--mobile"></div>
    </div>

  </div>
</template>

<script>
import Transition from '../scripts/Transition.js'
import loadFont from '../scripts/loadFont.js'

export default {
  name: 'MenuPage',
  mixins: [Transition, loadFont],
  data() {
    return {
      menuTop: '0',
      lastScrollTop: 0,
      menuVisible: true
    };
  },
  methods: {
    handleScroll() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      if (scrollTop === 0) {
        this.menuTop = '0';
      } else if (scrollTop > this.lastScrollTop) {
        this.menuTop = '-7%';
      } else {
        this.menuTop = '0';
      }

      this.lastScrollTop = scrollTop;
    },
    displayMenu() {
      if (this.menuVisible === true) {
        this.menuVisible = false
        this.disableScroll()
        document.getElementById('LAYER__MENU').classList.remove('start-mobile-scroll')
        document.getElementById('LAYER__MENU').classList.add('stop-mobile-scroll')
      }

      if (this.menuVisible === false) {
        this.menuVisible = true
        this.enableScroll()
        document.getElementById('LAYER__MENU').classList.remove('stop-mobile-scroll')
        document.getElementById('LAYER__MENU').classList.add('start-mobile-scroll')
      }

      document.querySelector('.menu--mobile--top').classList.add('top-important')
      document.querySelector('.menu--mobile--top').style.top = "0"
    },
    disableScroll() {
      window.addEventListener('wheel', this.preventDefault, { passive: false });
      window.addEventListener('touchmove', this.preventDefault, { passive: false });
      window.addEventListener('keydown', this.preventDefaultForScrollKeys);
    },
    enableScroll() {
      window.removeEventListener('wheel', this.preventDefault, { passive: false });
      window.removeEventListener('touchmove', this.preventDefault, { passive: false });
      window.removeEventListener('keydown', this.preventDefaultForScrollKeys);
    },
    preventDefault(e) {
      e.preventDefault();
    },
    preventDefaultForScrollKeys(e) {
      const keys = {
        37: 1, // left arrow
        38: 1, // up arrow
        39: 1, // right arrow
        40: 1, // down arrow
        32: 1, // spacebar
        33: 1, // page up
        34: 1, // page down
        35: 1, // end
        36: 1, // home
      };

      if (keys[e.keyCode]) {
        this.preventDefault(e);
        return false;
      }
    },
  },
  mounted() {
    const menuIcon = document.querySelector('.menu-icon');
    const menuToggle = document.querySelector('.burger-menu-toggle');
    const menuContainer = document.querySelector('.burger-menu-container');

    menuIcon.addEventListener('click', () => {
      menuToggle.classList.toggle('open');
      menuContainer.classList.toggle('open');
      document.documentElement.classList.toggle('no-scroll');
    });

    //SCROLL MENU HIDE - SHOW
    window.addEventListener('scroll', this.handleScroll);
  }
}
</script>

<style>
.menu--mobile--top {
  position: fixed;
  z-index: 1000000;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  background: transparent;
  height: 7vh;
  transition: all 0.55s ease;
}

.no-scroll {
  overflow: hidden !important;
}

.mobile--title {
  font-weight: 800;
  position: relative;
  z-index: 2000000;
  top: 0;
  cursor: pointer;
  left: 0;
  width: max-content;
  background: transparent;
  text-transform: uppercase;
  padding: 2.5% 5% 2%;
  font-size: clamp(1.1rem, 1vh, 10rem);
  transition: all 0.5s ease;
}

.menu-icon {
  position: relative;
  z-index: 10000;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  height: 3vh;
  top: -0.55vw;
  width: 3vh;
  margin-right: 5%;
  background: transparent;
  padding: 0vw;
}

.menu-icon__line {
  height: 2px;
  width: 100%;
  background-color: #333;
  transition: all 0.3s ease;
}

.menu-icon__line+.menu-icon__line {
  margin-top: 4px;
}

.burger-menu-toggle.menu-icon.open .menu-icon__line:nth-of-type(1) {
  transform: translateY(6px) rotate(45deg);
}

.burger-menu-toggle.menu-icon.open .menu-icon__line:nth-of-type(2) {
  opacity: 0;
}

.burger-menu-toggle.menu-icon.open .menu-icon__line:nth-of-type(3) {
  transform: translateY(-6px) rotate(-45deg);
}

.burger-menu-toggle {
  cursor: pointer;
}

.burger-menu-toggle:focus {
  outline: none;
}

.burger-menu li {
  display: block;
}

.burger-menu ul div {
  display: flex;
  flex-flow: column;
  margin-top: 2vh;
}

.burger-menu-container {
  position: fixed;
  z-index: 200000;
  top: -0.25vh;
  height: 100%;
  touch-action: none;
  pointer-events: none;
  padding: 2.5% 5% 2%;
}

.burger-menu-container .burger-menu .main-navigation,
.burger-menu-container .overlay--mobile {
  opacity: 0;
  pointer-events: none;
  transition: all 0.75s ease;
}


.burger-menu-container.open .burger-menu .main-navigation,
.burger-menu-container.open .overlay--mobile {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.75s ease;
}

.main-navigation {
  display: flex;
  flex-flow: column;
  width: 90vw;
  justify-content: space-between;
  position: relative;
  height: 100%;
  z-index: 200;
  top: 2vh;
  padding: 0;
  margin: 0;
  left: 0vw;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;
}

.burger-menu {
  display: block;
  height: 100%;
  position: relative;
  top: -6vh;
}

.burger-menu li {
  width: 100vw;
  text-align: left;
}

.burger-menu li span {
  display: block;
  font-weight: 500;
  font-size: clamp(10px, 4.2vw, 150px);
  padding-bottom: 0.5vh;
  line-height: 1.2;
}

.burger-menu li span.titre--1 {
  font-weight: 900;
  font-size: clamp(10px, 3.2vw, 130px);
  text-transform: uppercase;
  text-underline-offset: 1vh;
  text-decoration: underline;
  padding-bottom: 1.5vh;
  text-decoration-color: #727272;
}

.burger-menu li span.titre--2 {
  font-weight: 800;
  padding-bottom: 0.5vh;
  padding-top: 2.5vh;
  font-size: clamp(10px, 3.5vw, 150px);
  text-transform: uppercase;
}

.space-top {
  padding-top: 0.5vh;
}

.burger-menu li span.titre--3 {
  font-weight: 600;
  padding-bottom: 0vh;
  padding-top: 0.5vh;
  text-transform: uppercase;
  font-size: clamp(13px, 1vw, 150px);
}

.overlay--mobile {
  display: block;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 100%;
  max-height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease;
}
</style>