import WebFont from 'webfontloader';

export default {
    created() {
        WebFont.load({
            custom: {
                urls: [
                    'https://use.typekit.net/evu1xsd.css',
                    'https://use.typekit.net/ptd8amo.css'
                ],
                families: ['pragmatica', 'indivisible']
            },
            active: () => {
                document.documentElement.classList.add('fonts-loaded');
                this.showText(); // Appel pour afficher les textes une fois les polices chargées
                document.documentElement.dispatchEvent(new Event('fonts-loaded'));
            }
        });
    },
    methods: {
        showText() {
            const textElements = document.querySelectorAll('.hidden-text');
            textElements.forEach(element => {
                element.classList.remove('hidden-text');
                element.classList.add('visible-text');
            });
        },
    },
    mounted() {
        if (document.documentElement.classList.contains('fonts-loaded')) {
            this.showText(); // Assurez-vous que les textes sont également affichés si les polices sont déjà chargées
        } else {
            document.documentElement.addEventListener('fonts-loaded', () => {
                this.showText();
            });
        }
    }
};
