<template>
  <div id="ENTER__PAGE" class="main--gallery">

    <div v-if="!isVideoLoaded" class="loader">

      <div class="loader-enter">
        <svg class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
        </svg>
      </div>

    </div>

    <div id="DISPLAY__VIDEO" :style="{ overflow: 'hidden', display: isVideoLoaded ? 'block' : 'none' }">

      <div class="enter--black"></div>

      <div class="reveal--enterpage">
        <div class="wrapper-video">
          <video autoplay muted loop @canplaythrough="handleVideoLoaded">
            <source :src="videoURL" type="video/webm">
            <source :src="videoURLH265" type="video/mp4">
            <source :src="videoURLMp4" type="video/mp4">
          </video>
        </div>
      </div>

      <div id="text" style="z-index: 9999; pointer-events: none;">
        <h1><a class="link-enter hidden-text" @click="reveal()" style="text-transform:uppercase">ENTER</a></h1>
      </div>

      <div class="reveal--homepage"></div>

    </div>
  </div>
</template>

<script>
import Transition from '../../scripts/Transition.js'
import loadFont from '../../scripts/loadFont.js'

export default {
  name: 'EnterPage',
  mixins: [Transition, loadFont],
  data() {
    return {
      isVideoLoaded: false,
      previousRoute: this.$route.path,
      progress: 0,
      videoDuration: 0,
      titleEnter: '',
      videoURL: 'https://jonasforchini.closetonone.com/app/videos/jonas-forchini_loss-of-orientation_1080.webm',
      videoURLMp4: 'https://jonasforchini.closetonone.com/app/videos/jonas-forchini_loss-of-orientation_480.mp4',
      videoURLH265: 'https://jonasforchini.closetonone.com/app/videos/jonas-forchini_loss-of-orientation_h265.mp4'
    }
  },
  computed: {
    formattedProgress() {
      return this.formatProgress(this.progress);
    },
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/main/home.json')
        this.titleEnter = response.data.titleEnter
        this.downloadVideo();
      } catch (error) {
        console.error('Error fetching images data:', error)
      }
    },
    downloadVideo() {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', this.videoURL, true);
      xhr.responseType = 'blob';

      xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          this.progress = (event.loaded / event.total) * 100;
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          const videoBlob = new Blob([xhr.response], { type: 'video/webm' });
          this.videoURL = URL.createObjectURL(videoBlob);
          this.isVideoLoaded = true;
        }
      };

      xhr.onerror = () => {
        console.error('Error downloading video');
      };

      xhr.send();
    },
    handleVideoLoaded() {
      this.isVideoLoaded = true;
      this.progress = 100;
      setTimeout(() => {
        document.querySelector('.enter--black').style.opacity = "0";
      }, 50);
      setTimeout(() => {
        document.querySelector('#text').style.filter = "blur(0.5px)";
        document.querySelector('#text').style.opacity = "1";
      }, 4850);
      setTimeout(() => {
        document.querySelector('#text').style.pointerEvents = "auto";
      }, 5350)
    },
    formatProgress(progress) {
      return progress.toFixed(0);
    },
    reveal() {
      const text = document.getElementById('text')
      const letters = document.querySelectorAll('#text a > span i')

      letters.forEach((letter) => {
        letter.style.filter = "blur(25px)"
      })

      document.querySelector('.wrapper-video').style.filter = "blur(50px)"
      document.querySelector('.wrapper-video').style.transform = "scale(1.5)"
      document.querySelector('.wrapper-video').style.opacity = "0"

      this.trackEnter()

      text.classList.add('hide--pointer')

      setTimeout(() => {
        text.classList.add('hide')
      }, 150);

      setTimeout(() => {
        text.classList.add('hide--blur')
      }, 150);

      setTimeout(() => {
        document.querySelector('.block--background').classList.remove('hidden')
      }, 300);

      if (window.innerWidth > 768) {
        setTimeout(() => {
          this.goToHomeFromEnter()
        }, 300)
      }

      if (window.innerWidth < 768) {
        setTimeout(() => {
          this.goToHomeFromEnter()
        }, 300)
      }
    },
    gtag() {
      window.dataLayer.push(arguments);
    },
    trackEnter() {
            this.gtag('event', 'Click on Enter (Enter)', {
                'event_category': 'Interaction',
                'event_label': 'Click on Enter (Enter)'
            });
        }
  },
  mounted() {
    this.fetchImageData()

    setTimeout(() => {
      const firstLink = document.querySelector('.link-enter')
      firstLink.innerHTML = `<span>${firstLink.innerHTML.split("").join("</span><span>")}</span>`
      document.querySelector('.wrapper-video').style.filter = "blur(0px)"
      document.querySelector('.wrapper-video').style.transform = "scale(1)"
      document.querySelector('.wrapper-video').style.opacity = "1"
    }, 250);

    setTimeout(() => {
      document.querySelector('.menu--mobile').style.opacity = ""
    }, 2050)

    setTimeout(() => {
      document.querySelectorAll(".link-enter span").forEach((span) => {
        span.innerHTML = `<label>${span.innerHTML}</label>` + `<i>${span.innerHTML}</i>`.repeat(7)
      })
    }, 1050)

    document.querySelector('.main--menu').classList.add('hide')
    document.querySelector('.menu--mobile').style.zIndex = "1"
    document.querySelector('.menu--mobile--top').classList.add('hide')
    document.querySelector('.block--menu').classList.add('hide')

    document.querySelector('.mobile--title').style.background = "transparent"
    document.querySelector('.menu-icon').style.background = "transparent"
    document.querySelector('.menu--mobile--top').classList.add('top-important--home')
    document.querySelector('.burger-menu-container').classList.add('opacity--menu--home')
  },
  beforeUnmount() {
    document.querySelector('.main--menu').style.opacity = "0"
    document.querySelector('.menu--mobile').style.opacity = "0"
  }
}
</script>

<style>
@import url('./styles.css');
@import url('./styles__animations.css');
@import url('./styles__responsive.css');
</style>