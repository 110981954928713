<template>
  <div id="SOCIAL_MEDIA__PAGE" class="main--gallery">

    <div class="bio" style="z-index: 100;">

      <div class="texte--bio hidden-text">
        <span class="name-title">{{ title }}</span>

        <div class="links-contents">
          <div class="flex_content" v-for="(link, index) in links" :key="index">
            <a v-if="link.content" :href="link.href" :target="link.blank" @click="trackLink" :data-content="link.content">{{ link.content }}</a>
          </div>
        </div>

      </div>

    </div>

    <FooterCard />

  </div>
</template>

<script>
import loadFont from '../scripts/loadFont.js';
import FooterCard from './FooterCard.vue'

export default {
  name: 'SocialPage',
  components: { FooterCard },
  mixins: [loadFont],
  data() {
    return {
      title: "",
      links: [],
    }
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/main/social-media.json');
        this.title = response.data.title;
        this.links = response.data.links;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    },
    decodeHTML(value) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = value;
      return textarea.value;
    },
    gtag() {
      window.dataLayer.push(arguments);
    },
    trackLink(event) {
      const link = event.target.getAttribute('data-content');

      const tagger = 'Click on ' + link + ' (Social Media)';

      this.gtag('event', tagger, {
        'event_category': 'Interaction',
        'event_label': "Click on" + link
      });

      this.gtag('event', 'Click on links (Social Media)', {
        'event_category': 'Interaction',
        'event_label': 'All links clicked (Social Media)'
      });
    }
  },
  mounted() {
    this.fetchImageData();
    document.querySelector('.menu--mobile--top').classList.add('top-important')
  },
  unmounted() {
    document.querySelector('.menu--mobile--top').classList.remove('top-important')
  }
}
</script>

<style scoped>
.bio {
  display: flex;
  position: relative;
  top: 15vh;
  width: 100vw;
  padding-left: 10vw;
  height: auto;
  justify-content: flex-start;
}

.bio span {
  display: block;
  padding-top: 0.5vh;
}

.bio span.name-title {
  text-transform: uppercase;
  font-size: clamp(14px, 1.2vw, 105px);
  font-weight: 800;
  display: none;
}

.flex_content {
  margin-bottom: 0.5vh;
}

.flex_content a {
  font-weight: 500;
  font-size: clamp(14px, 1.6vw, 105px);
  line-height: 1.5;
  text-decoration: underline;
  -webkit-text-decoration-color: transparent;
  text-decoration-color: transparent;
  text-transform: uppercase;
  text-underline-offset: 0.5vh;
  transition: all 0.55s ease;
}

.flex_content a:hover {
  text-decoration-color: black;
  transition: all 0.55s ease;
}

.links-contents {
  padding: 2vw;
}

@media only screen and (max-width: 768px) {

  #SOCIAL_MEDIA__PAGE {
    margin-top: 0vh;
  }

  .bio {
    display: flex;
    position: fixed;
    top: 0;
    width: 90vw;
    left: 3vw;
    padding-left: 0;
    flex-flow: column;
    text-align: center;
    height: 90%;
    align-items: center;
    justify-content: center;
  }

  .bio span.name-title {
    font-size: clamp(14px, 2vh, 105px);
    display: none;
  }

  .flex_content a {
    font-size: clamp(14px, 3vh, 105px);
    text-transform: uppercase;
  }

}
</style>