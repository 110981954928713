<template>
    <div id="DUMMY__SLIDER" class="contain__dummy">

        <div class="dummy--flex">
            <div class="dummy--gallery">

                <div class="dummy--texte hidden-text">

                    <div class="info_two" v-if="title">
                        <span>Title:</span><span v-html="decodeHTML(title)"></span>
                    </div>

                    <div class="info_two" v-if="year">
                        <span>Year:</span><span v-html="decodeHTML(year)"></span>
                    </div>

                    <div class="info_two" v-if="pages">
                        <span>Pages:</span><span v-html="decodeHTML(pages)"></span>
                    </div>

                    <div class="info_two" v-if="size">
                        <span>Size:</span><span v-html="decodeHTML(size)"></span>
                    </div>

                    <div class="info_two" v-if="text">
                        <span>Text:</span><span v-html="decodeHTML(text)"></span>
                    </div>

                    <div class="info_two" v-if="graphics">
                        <span>Graphism Concept:</span><span v-html="decodeHTML(graphics)"></span>
                    </div>

                    <div class="paraph" v-if="description">
                        <span class="info_desc">Description :</span>
                        <span class="paraph_desc" v-html="decodeHTML(description)"></span>
                    </div>

                </div>

                <div class="gallery" @keydown="handleKeyDown" tabindex="0" ref="gallery">

                    <img @click="handleImageClick" @touchstart="handleTouchStart" @touchmove="handleTouchMove"
                        @touchend="handleTouchEnd" :src="currentImage.src" :alt="currentImage.alt"
                        class="image load__image" :data-src-load="currentImage.src" v-if="images.length > 0"
                        tabindex="0" />

                    <div class="arrows">
                        <button @click="prevImage" :disabled="currentIndex === 0">Prev.</button>
                        <button @click="nextImage" :disabled="currentIndex === images.length - 1">Next</button>
                    </div>

                    <div class="counter hidden-text">
                        <b>{{ currentIndex + 1 }} </b>&nbsp;| {{ images.length }}
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import loadFont from '../../../scripts/loadFont.js'

export default {
    name: 'GalleryDummySliderTwo',
    props: {
        imagesData: {
            type: Object,
            required: true
        }
    },
    mixins: [loadFont],
    data() {
        return {
            currentIndex: 0,
            images: [],
            description: '',
            title: '',
            year: '',
            pages: '',
            size: '',
            graphics: '',
            text: '',
            touchStartX: 0,
            touchStartY: 0,
            touchEndX: 0,
            touchEndY: 0,
        }
    },
    computed: {
        currentImage() {
            return this.images[this.currentIndex];
        }
    },
    methods: {
        nextImage() {
            if (this.currentIndex < this.images.length - 1) {
                this.currentIndex++;
            }
        },
        prevImage() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            }
        },
        handleImageClick(event) {
            const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
            if (!isTouchDevice) {
                const clickPosition = event.clientX - event.target.getBoundingClientRect().left;
                const imageWidth = event.target.clientWidth;

                if (clickPosition / imageWidth >= 0.5) {
                    this.nextImage();
                } else {
                    this.prevImage();
                }
            }
        },
        handleKeyDown(event) {
            switch (event.keyCode) {
                case 37: // Left arrow key
                    this.prevImage();
                    break;
                case 39: // Right arrow key
                    this.nextImage();
                    break;
            }
        },
        handleTouchStart(event) {
            this.touchStartX = event.touches[0].clientX;
            this.touchStartY = event.touches[0].clientY;
        },
        handleTouchMove(event) {
            this.touchEndX = event.touches[0].clientX;
            this.touchEndY = event.touches[0].clientY;
        },
        handleTouchEnd() {
            this.handleSwipe();
        },
        handleSwipe() {
            const deltaX = this.touchEndX - this.touchStartX;
            const deltaY = this.touchEndY - this.touchStartY;

            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                if (deltaX > 50) {
                    this.prevImage();
                } else if (deltaX < -50) {
                    this.nextImage();
                }
            }
        },
        decodeHTML(value) {
            const textarea = document.createElement('textarea');
            textarea.innerHTML = value;
            return textarea.value;
        },
        loadImage(img) {
            return new Promise((resolve, reject) => {
                if (img.complete) {
                    resolve(img);
                } else {
                    img.onload = () => resolve(img);
                    img.onerror = () => reject(new Error('Failed to load image: ' + img.dataset.srcLoad));
                }
                img.src = img.dataset.srcLoad;
            });
        },
        loadGallery() {
            const gallery = document.querySelector('.main--gallery');
            const images = document.querySelectorAll('.load__image');
            const loader = document.querySelector('.loader--gallery');

            const imagePromises = Array.from(images).map(img => this.loadImage(img));

            Promise.all(imagePromises)
                .then(() => {
                    gallery.classList.remove('hidden-gallery');
                    loader.style.opacity = '0';
                })
                .catch(error => {
                    console.error('An error occurred while loading images:', error);
                });
        },
    },
    created() {
        setTimeout(() => {
            this.title = this.imagesData.title;
            this.year = this.imagesData.year;
            this.pages = this.imagesData.pages;
            this.size = this.imagesData.size;
            this.graphics = this.imagesData.graphics;
            this.text = this.imagesData.text;
            this.description = this.imagesData.description;
            this.images = this.imagesData.gallery__slider;
        }, 500);
    },
    mounted() {
        this.$refs.gallery.focus();

        document.querySelector('.main--gallery').classList.add('hidden-gallery')

        const loaderG = document.querySelector('.loader--gallery')
        loaderG.classList.remove('hide')
        loaderG.style.display = "flex"
        loaderG.style.opacity = "1"

        setTimeout(() => {
            this.loadGallery()
        }, 800)
    },
    unmounted() {
        document.querySelector('.main--gallery').style.marginTop = ""
    }
}
</script>

<style>
@import url('../../../styles/gallery__dummy__slider__two.css');
</style>
