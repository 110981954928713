<template>

    <div class="list--mobile hidden-text">

        <div class="naming">Jonàs Forchini</div>

        <div class="projects_home">
            <label>Practice From the Bottom</label>
            <span @click="goToMurky()">An Apprencticeship to Murky Depths</span>
            <span @click="goToSpectrum()">Spectrum</span>
        </div>

        <div class="projects_home">
            <label>Mangrovines</label>
            <span @click="goToSalty()">Salty Territories</span>
            <span @click="goToArea()">Area
                34</span>
            <span @click="goToRopes()">On The Ropes</span>
        </div>

        <div class="dummys_home">
            <label style="margin-bottom: 1vw">Dummys</label>
            <span @click="goToUnderwater()">Brief History of<br />Underwater Photography</span>
            <span @click="goToTerritories()">Mangrovines :<br />Salty Territories</span>
            <span @click="goToKancho()">Kancho</span>
        </div>

    </div>

</template>

<script>
import Transition from '../../scripts/Transition.js'
import loadFont from '../../scripts/loadFont.js'

export default {
    mixins: [Transition, loadFont],
    mounted() {
        document.querySelector('.menu-icon').addEventListener('click', () => {
            document.querySelector('.list--mobile').classList.toggle('opacity--list')
            document.querySelector('.list--mobile').classList.toggle('delay')
        })

        const elements = document.querySelectorAll('.list--mobile div, .list--mobile span, .list--mobile label');

        this.$gsap.from(elements, {
            opacity: 0,
            duration: 3,
            stagger: 0.1,
            ease: 'power2.out'
        });


    },
    beforeUnmount() {
        document.querySelector('.menu-icon').removeEventListener('click', () => {
            document.querySelector('.list--mobile').classList.toggle('opacity--list')
            document.querySelector('.list--mobile').classList.toggle('delay')
        })
    }
}
</script>