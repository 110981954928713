<template>
  <div id="EXHIBITIONS__PAGE" class="main--gallery">

    <GalleryVertical :imagesData="imagesData" />
  </div>
</template>

<script>
import GalleryVertical from '../modules/gallery_vertical/GalleryVertical.vue'

export default {
  name: 'ExhibitionsPage',
  components: { GalleryVertical },
  data() {
    return {
      titre: "",
      imagesData: {}
    }
  },
  mounted() {
    this.fetchImageData();
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/projects/exhibitions.json');
        this.imagesData = response.data;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    },
    decodeHTML(value) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = value;
      return textarea.value;
    },
  }
}
</script>

<style>
.titre--documentation {
  font-weight: 800;
  text-transform: uppercase;
  position: fixed;
  bottom: 6vh;
  left: 4vw;
  font-size: clamp(20px, 5vw, 150px);
}

@media only screen and (max-width: 768px) {
  #EXHIBITIONS__PAGE .words {
    width: 100% !important;
    padding: 5vh 0vw 5vh 3vw !important;
    top: 5vh !important;
    margin-top: -10vh !important;
  }

  #EXHIBITIONS__PAGE .height-small {
    width: 100% !important;
    padding: 5vh 0vw 5vh 3vw !important;
    top: 5vh !important;
    margin-top: -10vh !important;
    height: 40vh;
  }

  #EXHIBITIONS__PAGE span {
    font-size: clamp(9px, 1.1vh, 800px);
  }
}
</style>