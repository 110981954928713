<template>
    <section id="GALLERY__VERTICAL" v-for="(section, index) in sections__vertical" :key="index" :style="{
        background: section.valueBackground,
        top: section.valueTop + 'vw',
        left: section.valueLeft + 'vw',
        paddingTop: section.valuePadTop + 'vw',
        paddingBottom: section.valuePadBottom + 'vw',
        marginTop: section.valueMarginTop + 'vw',
        marginBottom: section.valueMarginBottom + 'vw',
        zIndex: section.valueIndex
    }" :class="['gallery vertical each',
        section.class1,
        section.class2,
        section.class3,
        section.class4,
        section.class5,
        section.class6,
        section.classSection
    ]">

        <template v-if="hasImages(section)">
            <template v-for="(image, i) in section.images">

                <img :alt="image.alt" :key="i" v-if="image.src" :src="image.isLoaded ? image.src : image.placeholderSrc"
                    :data-src-lazy="image.placeholderSrc" @load="onImageLoad(image)" @click="toggleZoom" :style="{
                        top: image.valueImgTop + 'vw',
                        left: image.valueImgLeft + 'vw',
                        paddingLeft: image.valueImgPadLeft + 'vw',
                        zIndex: image.valueImgIndex,
                        paddingRight: image.valueImgPadRight + 'vw',
                        width: image.valueImgWidth + 'vw',
                        maxHeight: image.valueImgMaxHeight + 'vh',
                        transform: 'rotate(' + image.valueImgRotate + 'deg)',
                        mixBlendMode: image.valueImgColor
                    }" :class="[]" />

                <div :key="i" v-if="section.class6 !== 'v-zero' && image.iframeID" class="video STOP__SCROLL"
                    @mouseenter="applyStyleToParent($event)" @mouseleave="removeStyleFromParent($event)"
                    @click="unmuteVideo">
                    <iframe ref="ytplayer"
                        title="Jonàs Forchini - Video & Photography works - Portfolio - Underwater Photography"
                        :id="'ytplayer-' + i" type="text/html" :style="{
                            top: image.valueImgTop + 'vw',
                            left: image.valueImgLeft + 'vw',
                            paddingLeft: image.valueImgPadLeft + 'vw',
                            paddingRight: image.valueImgPadRight + 'vw',
                            zIndex: image.valueImgIndex,
                            aspectRatio: image.valueImgAspectRatio,
                            transform: 'rotate(' + image.valueImgRotate + 'deg)',
                            mixBlendMode: image.valueImgColor
                        }"
                        :src="'https://www.youtube.com/embed/' + image.iframeID + '?autoplay=1&mute=1&controls=0&enablejsapi=1&modestbranding=1&playsinline=1&color=white&iv_load_policy=3&loop=1&playlist=' + image.iframeID"
                        frameborder="0" allowfullscreen allow="autoplay" :class="[]"></iframe>
                </div>

                <div :key="i" v-if="section.class6 === 'v-zero' && image.iframeID" class="video STOP__SCROLL"
                    @mouseenter="showTooltip" @mouseleave="hideTooltip" @mousemove="moveTooltip" @click="soundVideo">
                    <iframe title="Jonàs Forchini - Video & Photography works - Portfolio - Underwater Photography"
                        ref="ytplayer" :id="'ytplayer-' + i" type="text/html" :style="{
                            top: image.valueImgTop + 'vw',
                            left: image.valueImgLeft + 'vw',
                            width: image.valueImgWidth + 'vw',
                            paddingLeft: image.valueImgPadLeft + 'vw',
                            paddingRight: image.valueImgPadRight + 'vw',
                            zIndex: image.valueImgIndex,
                            aspectRatio: image.valueImgAspectRatio,
                            transform: 'rotate(' + image.valueRotate + 'deg)',
                            mixBlendMode: image.valueImgColor
                        }"
                        :src="'https://www.youtube.com/embed/' + image.iframeID + '?autoplay=1&mute=1&controls=0&enablejsapi=1&modestbranding=1&playsinline=1&color=white&iv_load_policy=3&loop=1&playlist=' + image.iframeID"
                        frameborder="0" allowfullscreen allow="autoplay" :class="[]" data-muted="true"></iframe>
                </div>

                <div :key="i" v-if="image.textSrc" class="words" :style="{
                    top: image.valueImgTop + 'vw',
                    left: image.valueImgLeft + 'vw',
                    width: image.valueImgWidth + 'vw',
                    transform: 'rotate(' + image.valueImgRotate + 'deg)',
                    zIndex: image.valueImgIndex,
                    fontSize: 'clamp(9px,' + image.valueFontSize + 'vw, 800px)',
                    fontFamily: image.valueFontFamily,
                    fontWeight: image.valueFontWeight,
                    fontStyle: image.valueFontStyle,
                    textTransform: image.valueFontCase,
                    textOrientation: image.valueFontTextOrientation,
                    writingMode: image.valueFontWritingMode,
                    lineHeight: image.valueFontLineHeight,
                    letterSpacing: image.valueFontLetterSpacing + 'vw',
                    color: image.valueFontColor,
                    background: image.valueFontBgColor,
                    textIndent: image.valueFontTextIndent + 'vw',
                    textAlign: image.valueFontTextAlign,
                    padding: image.valueFontPadding + 'vw',
                    borderRadius: image.valueFontBorderRadius + '%'
                }">
                    <span :key="i" v-if="image.textSrc" v-html="decodeHTML(image.textSrc)">
                    </span>
                </div>

            </template>

        </template>
    </section>

    <div id="tooltip" class="tooltip" ref="tooltip">Click to activate the sound</div>

</template>

<script>
import loadFont from '../../../scripts/loadFont.js'

export default {
    name: 'GalleryVertical',
    mixins: [loadFont],
    props: {
        imagesData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            imageData: {},
            sections__vertical: [],
            visibleSections: 5,
            increment: 5,
            lastScrollPosition: 0
            // in sections__vertical.slice(0, visibleSections)
        }
    },
    computed: {
        filteredImages() {
            return (section) => {
                return section.images.filter(image => image.src);
            };
        }
    },
    methods: {
        onImageLoad(image) {
            image.isLoaded = true;
        },
        checkScrollPosition() {
            const scrollPosition = window.scrollY + window.innerHeight;
            const documentHeight = document.body.offsetHeight;

            if (scrollPosition >= (documentHeight - (150 * window.innerHeight / 100))) {
                this.visibleSections += this.increment;
            }

            this.lastScrollPosition = scrollPosition;
        },
        hasImages(section) {
            return section.images && section.images.length > 0;
        },
        decodeHTML(value) {
            const textarea = document.createElement('textarea');
            textarea.innerHTML = value;
            return textarea.value;
        },
        applyStyleToParent(event) {
            this.showTooltip()
            event.currentTarget.closest('.gallery').classList.add('hovered')
            this.startOpacityTimeout()
        },
        centerViewVideo(element) {
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }, 100)
        },
        removeStyleFromParent(event) {
            event.currentTarget.closest('.gallery').classList.remove('hovered')
            clearTimeout(this.opacityTimeout)
            this.resetMainMenuOpacity()
            this.muteVideo(event)
            this.hideTooltip()
        },
        startOpacityTimeout() {
            this.opacityTimeout = setTimeout(() => {
                this.reduceMainMenuOpacity()
            }, 100);
        },
        reduceMainMenuOpacity() {
            document.querySelector('.main--menu').style.opacity = 0
            document.querySelector('.main--gallery').style.backgroundColor = "black"
            document.querySelectorAll('img').forEach(img => {
                img.style.opacity = 0
            });
            document.querySelectorAll('.video').forEach(video => {
                video.style.cursor = "none";
            });
        },
        resetMainMenuOpacity() {
            document.querySelector('.main--menu').style.opacity = "";
            document.querySelector('.main--gallery').style.backgroundColor = "";
            document.querySelectorAll('img').forEach(img => {
                img.style.opacity = "";
            });
            document.querySelectorAll('.video').forEach(video => {
                video.style.cursor = "ne-resize";
            });
        },
        unmuteVideo(event) {
            const iframe = event.currentTarget.querySelector('iframe');

            // Post a message to the iframe to unmute
            iframe.contentWindow.postMessage(JSON.stringify({
                event: 'command',
                func: 'unMute',
                args: []
            }), '*');

            this.centerViewVideo(event.currentTarget);
        },
        muteVideo(event) {
            const iframe = event.currentTarget.querySelector('iframe');

            // Post a message to the iframe to unmute
            iframe.contentWindow.postMessage(JSON.stringify({
                event: 'command',
                func: 'mute',
                args: []
            }), '*');
        },
        soundVideo(event) {
            const iframe = event.currentTarget.querySelector('iframe');
            const isMuted = iframe.getAttribute('data-muted') === 'true';

            if (isMuted) {
                // Unmute the video
                iframe.contentWindow.postMessage(JSON.stringify({
                    event: 'command',
                    func: 'unMute',
                    args: []
                }), '*');
                iframe.setAttribute('data-muted', 'false');

                const tooltip = this.$refs.tooltip;
                tooltip.textContent = 'Click to mute the video'

                this.trackSound()
            } else {
                // Mute the video
                iframe.contentWindow.postMessage(JSON.stringify({
                    event: 'command',
                    func: 'mute',
                    args: []
                }), '*');
                iframe.setAttribute('data-muted', 'true');

                const tooltip = this.$refs.tooltip
                tooltip.textContent = 'Click to activate the sound'

                this.trackMute()
            }
        },
        showTooltip() {
            const tooltip = this.$refs.tooltip
            tooltip.style.display = 'block'
        },
        hideTooltip() {
            const tooltip = this.$refs.tooltip
            tooltip.style.display = 'none'
        },
        moveTooltip(event) {
            const tooltip = this.$refs.tooltip
            tooltip.style.left = event.pageX + 'px'
            tooltip.style.top = event.pageY + 'px'
        },
        toggleZoom(event) {
            const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

            if (isTouchDevice && window.innerWidth < 768) {
                const galleries = document.querySelectorAll('.each')
                const element = event.currentTarget; // Obtenir l'élément galerie qui a été cliqué

                galleries.forEach((gallery) => {
                    gallery.classList.toggle('zoom-active')
                })

                // Si la classe zoom-active est ajoutée, faire défiler pour garder l'élément visible
                const scrollOptions = {
                    behavior: 'auto',
                    block: 'start'
                };
                element.scrollIntoView(scrollOptions);

                this.trackZoomMobile()
            }
        },
        loadImage(img) {
            return new Promise((resolve, reject) => {
                if (img.complete) {
                    resolve(img);
                } else {
                    img.onload = () => resolve(img);
                    img.onerror = () => reject(new Error('Failed to load image: ' + img.dataset.srcLazy));
                }
                img.src = img.dataset.srcLazy; // Charge l'image depuis data-src-lazy
            });
        },
        loadGallery() {
            const gallery = document.querySelector('.main--gallery');
            const images = gallery.querySelectorAll('img');

            const loader = document.querySelector('.loader--gallery');

            const imagePromises = Array.from(images).map(img => this.loadImage(img));

            Promise.all(imagePromises)
                .then(() => {
                    gallery.classList.remove('hidden-gallery');
                    loader.style.opacity = '0';
                })
                .catch(error => {
                    console.error('An error occurred while loading images:', error);
                });
        },
        gtag() {
            window.dataLayer.push(arguments);
        },
        trackMute() {
            const title = document.title
            this.gtag('event', 'Mute video (' + title + ')', {
                'event_category': 'Interaction',
                'event_label': 'Mute video (' + title + ')'
            });

            this.gtag('event', 'Mute video (All)', {
                'event_category': 'Interaction',
                'event_label': 'Mute video (All)'
            });
        },
        trackSound() {
            const title = document.title
            this.gtag('event', 'Activate sound (' + title + ')', {
                'event_category': 'Interaction',
                'event_label': 'Activate sound (' + title + ')'
            });

            this.gtag('event', 'Activate sound (All)', {
                'event_category': 'Interaction',
                'event_label': 'Activate sound (All)'
            });
        },
        trackZoomMobile() {
            const title = document.title
            this.gtag('event', 'Zoom on Mobile (' + title + ')', {
                'event_category': 'Interaction',
                'event_label': 'Zoom on Mobile (' + title + ')'
            });

            this.gtag('event', 'Zoom on Mobile (All)', {
                'event_category': 'Interaction',
                'event_label': 'Zoom on Mobile (All)'
            });
        }
    },
    created() {
        setTimeout(() => {
            this.sections__vertical = this.imagesData.gallery__vertical
        }, 800)
    },
    mounted() {
        document.querySelector('.main--gallery').classList.add('hidden-gallery')

        const loaderG = document.querySelector('.loader--gallery')
        loaderG.classList.remove('hide')
        loaderG.style.display = "flex"
        loaderG.style.opacity = "1"

        setTimeout(() => {
            this.loadGallery()
        }, 800)
    },
    unmounted() {
        document.querySelector('.main--gallery').classList.remove('visible-gallery')
        const loaderG = document.querySelector('.loader--gallery')
        loaderG.style.display = "none"
        loaderG.style.opacity = ""
        loaderG.classList.add('hide')
    }
}
</script>

<style>
.v-lazy-image {
    filter: blur(2px) !important;
    transition: filter 0.4s !important;
    will-change: filter !important;
}

.v-lazy-image-loaded {
    filter: blur(0) !important;
}
</style>