<template>

  <MenuPage v-if="!isEnterPage" />
  <MenuMobile v-if="!isEnterPage" />

  <div class="block--transition--black hidden"></div>
  <div class="block--background hidden"></div>

  <MenuPage v-if="isEnterPage" style="opacity:0" />
  <MenuMobile v-if="isEnterPage" style="opacity:0" />

  <router-view v-slot="{ Component }">
    <transition :name="transitionName" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  <LinkSEO />

  <div class="loader--gallery hide">
    <svg class="circular--g" viewBox="25 25 50 50">
      <circle class="path--g" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
    </svg>
  </div>


  <CookieBanner />


</template>

<script>
import Cookies from 'js-cookie'
import CookieBanner from './plugins/CookieBanner.vue'

import MenuPage from './components/MenuPage.vue'
import MenuMobile from './components/MenuMobile.vue'
import LinkSEO from './components/LinkSEO.vue'

export default {
  name: 'App',
  components: { MenuPage, MenuMobile, LinkSEO, CookieBanner },
  data() {
    return {
      transitionName: 'slideNormal',
      transitioning: false,
      previousRoute: null,
      displayCookie: true,
      cookiesAccepted: false
    }
  },
  created() {
    this.previousRoute = this.$route.path;

    this.checkCookieStatus();
    setInterval(this.checkCookieStatus, 30000);
    // Vérifiez si le cookie `cookiesRejected` existe initialement
    if (Cookies.get('cookiesRejected')) {
      this.accepted = false;
      setTimeout(() => {
        this.displayCookieBanner();
      }, 60000);
    }
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    isEnterPage() {
      return this.$route.path === '/enter' || this.$route.path === '/';
    }
  },
  watch: {
    '$route'() {
      this.transitioning = true
    },
    currentRoute(newRoute) {
      // Vérifiez si la nouvelle route est la page d'accueil
      const isNewRouteHome = newRoute === "/home"
      // Vérifiez si la route précédente était la page d'accueil
      const isPreviousRouteHome = this.previousRoute === "/enter" || this.previousRoute === "/";

      if (isNewRouteHome && isPreviousRouteHome) {
        this.transitionName = "slideEnter";
      } else {
        this.transitionName = this.$route.meta.transition;
      }
      // Mettre à jour la route précédente
      this.previousRoute = newRoute;
    }
  },
  methods: {
    checkCookieStatus() {
      const cookiesRejected = Cookies.get('cookiesRejected');
      if (cookiesRejected) {
        this.accepted = false;
        this.displayCookieBanner();
      }
    },
    displayCookieBanner() {
      const banner = document.querySelector('.height_banner')
      if (banner) {
        banner.style.display = "block";
        banner.style.opacity = "1";
        var alertText = document.getElementById('alert-text');
        alertText.style.display = 'block';
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.transitioning = false
    next()
  },
  mounted() {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

    if (!isTouchDevice) {
      const APP__HEIGHT = document.getElementById('app')
      window.addEventListener('wheel', function () {
        APP__HEIGHT.style.height = "max-content";
        setTimeout(() => {
          APP__HEIGHT.style.height = "100%";
        }, 10);
      });
    }
  }
}
</script>

<style>
@import url("https://use.typekit.net/ptd8amo.css");
@import url("https://use.typekit.net/evu1xsd.css");
@import url("https://use.typekit.net/pip0aal.css");

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVc.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVc.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4gaVc.ttf) format('truetype');
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500');
/* CMS_2024 */

@import url('./styles/reset.css');
@import url('./styles/base.css');
@import url('./styles/home.css');
@import url('./styles/menu.css');
@import url('./styles/gallery.css');
@import url('./styles/gallery__vertical.css');
@import url('./styles/transitions.css');
@import url('./styles/responsive.css');

#app {
  position: absolute;
  top: 0px;
  left: 0px;
  height: auto;
  /* transition: zoom 0.8s ease; */
}

.blurred--content {
  display: none !important;
}

.block--background {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('https://jonasforchini.closetonone.com/app/img/home/jonas-forchini_homepage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  transition: all 0.5s ease;
}

.hidden-text {
  visibility: hidden;
}

.visible-text {
  visibility: visible;
}

.hidden-gallery {
  opacity: 0 !important;
  transition: all 0.75s ease;
}

.visible-gallery {
  opacity: 1;
  transition: all 0.75s ease;
}

@media only screen and (max-width: 768px) {

  .block--background {
    position: fixed;
  }
}
</style>
