import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios';

import HomePage from './components/PAGE__HOME/HomePage.vue'
import EnterPage from './components/PAGE__HOME/EnterPage.vue'
import AboutPage from '../src/components/AboutPage.vue'
import ContactPage from '../src/components/ContactPage.vue'
import SocialPage from '../src/components/SocialPage.vue'
import DocumentationPage from '../src/components/DocumentationPage.vue'
import PrintsPage from '../src/components/PrintsPage.vue'
import PortfolioPage from '../src/components/PortfolioPage.vue'
// MANGROVINES
import SaltyPage from './components/mangrovines/SaltyPage.vue'
import AreaPage from './components/mangrovines/AreaPage.vue'
import RopesPage from './components/mangrovines/RopesPage.vue'
// PRACTICE FROM THE BOTTOM
import MurkyPage from '../src/components/practice/MurkyPage.vue'
import SpectrumPage from '../src/components/practice/SpectrumPage.vue'
// DUMMYS
import UnderwaterPage from '../src/components/dummys/UnderwaterPage.vue'
import TerritoriesPage from '../src/components/dummys/TerritoriesPage.vue'
import KanchoPage from '../src/components/dummys/KanchoPage.vue'
// EXHIBITIONS
// import ExhibitionsGreenPage from '../src/components/exhibitions/ExhibitionsGreenPage.vue'
// import ExhibitionsMurkyPage from '../src/components/exhibitions/ExhibitionsMurkyPage.vue'
import ExhibitionsPage from '../src/components/exhibitions/ExhibitionsPage.vue'


const routes = [
    {
        path: '/',
        redirect: '/enter' // Redirige la route racine vers /enter
    },
    {
        name: 'HomePage',
        path: '/home',
        route: '/home',
        component: HomePage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
    {
        name: 'EnterPage',
        path: '/enter',
        route: '/enter',
        component: EnterPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
    {
        name: 'AboutPage',
        path: '/about',
        route: '/about',
        component: AboutPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
    {
        name: 'ContactPage',
        path: '/contact',
        route: '/contact',
        component: ContactPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
    {
        name: 'SocialPage',
        path: '/social-media',
        route: '/social-media',
        component: SocialPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
    {
        name: 'DocumentationPage',
        path: '/documentation',
        route: '/documentation',
        component: DocumentationPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
    {
        name: 'PrintsPage',
        path: '/prints',
        route: '/prints',
        component: PrintsPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
    {
        name: 'PortfolioPage',
        path: '/portfolio',
        route: '/portfolio',
        component: PortfolioPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },

    // MANGROVINES
    {
        name: 'SaltyPage',
        path: '/salty-territories',
        route: '/salty-territories',
        component: SaltyPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
    {
        name: 'AreaPage',
        path: '/area-34',
        route: '/area-34',
        component: AreaPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
    {
        name: 'RopesPage',
        path: '/on-the-ropes',
        route: '/on-the-ropes',
        component: RopesPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },

    // PRACTICE FROM THE BOTTOM
    {
        name: 'MurkyPage',
        path: '/an-apprenticeship-to-murky-depths',
        route: '/an-apprenticeship-to-murky-depths',
        component: MurkyPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },
    {
        name: 'SpectrumPage',
        path: '/spectrum',
        route: '/spectrum',
        component: SpectrumPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    },

    // DUMMYS
    {
        path: '/dummys',
        children: [
            {
                name: 'UnderwaterPage',
                path: 'brief-history-of-underwater-photography',
                component: UnderwaterPage,
                meta: {
                    transition: 'slideNormal',
                    title: '',
                    description: '',
                    keywords: ''
                },
            },
            {
                name: 'TerritoriesPage',
                path: 'mangrovines-salty-territories',
                component: TerritoriesPage,
                meta: {
                    transition: 'slideNormal',
                    title: '',
                    description: '',
                    keywords: ''
                },
            },
            {
                name: 'KanchoPage',
                path: 'kancho',
                component: KanchoPage,
                meta: {
                    transition: 'slideNormal',
                    title: '',
                    description: '',
                    keywords: ''
                },
            },
        ]
    },

    // EXHIBITIONS
    {
        name: 'ExhibitionsPage',
        path: '/exhibitions',
        route: '/exhibitions',
        component: ExhibitionsPage,
        meta: {
            transition: 'slideNormal',
            title: '',
            description: '',
            keywords: ''
        },
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, from, next) => {
    const routeName = to.path;
    // const routeMeta = to.path === '/enter' ? '/home' : to.path;
    const routeMeta = to.path;
    let jsonData;

    try {
        const response = await axios.get(process.env.VUE_APP_API_URL + `/meta${routeMeta}.json`);
        jsonData = response.data;

        // Mettre à jour les métadonnées de la page
        document.title = to.meta.title = jsonData.title;

        const updateMetaTag = (name, content) => {
            let tag = document.querySelector(`meta[name="${name}"]`);
            if (tag) {
                tag.content = content;
            } else {
                tag = document.createElement('meta');
                tag.name = name;
                tag.content = content;
                document.head.appendChild(tag);
            }
        };

        if (jsonData.description) {
            updateMetaTag('description', jsonData.description);
        } else {
            const existingDescription = document.head.querySelector('meta[name="description"]');
            if (existingDescription) {
                existingDescription.remove();
            }
        }

        if (jsonData.keywords) {
            updateMetaTag('keywords', jsonData.keywords);
        } else {
            const existingKeywords = document.head.querySelector('meta[name="keywords"]');
            if (existingKeywords) {
                existingKeywords.remove();
            }
        }
    } catch (error) {
        console.error(`Impossible de charger les données JSON pour la page ${routeName}: `, error);
        // Vous pouvez choisir de gérer l'erreur en naviguant vers une page d'erreur ou en affichant un message
    }

    next();
});

export default router;