<template>
  <div id="MURKY__PAGE" class="main--gallery" :style="{ background: bgColor }">
    <GalleryVertical :imagesData="imagesData" />
  </div>
</template>

<script>
import GalleryVertical from '../modules/gallery_vertical/GalleryVertical.vue';

export default {
  name: 'MurkyPage',
  components: { GalleryVertical },
  data() {
    return {
      imagesData: {},
      bgColor: ''
    }
  },
  created() {
    this.fetchImageData();
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/projects/an-apprenticeship-to-murky-depths.json');
        this.imagesData = response.data;
        this.bgColor = response.data.bgColor;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    }
  }
}
</script>