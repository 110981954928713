<template>
  <div id="SALTY_TERRITORIES__PAGE" class="main--gallery" :style="{ background: bgColor }">
    <GalleryVertical :imagesData="imagesData" />
  </div>
</template>

<script>
import GalleryVertical from '../modules/gallery_vertical/GalleryVertical.vue'

export default {
  name: 'SaltyPage',
  components: { GalleryVertical },
  data() {
    return {
      imagesData: {},
      bgColor: ''
    }
  },
  mounted() {
    this.fetchImageData();
  },
  methods: {
    async fetchImageData() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + '/public/projects/salty-territories.json');
        this.imagesData = response.data;
        this.bgColor = response.data.bgColor;
      } catch (error) {
        console.error('Error fetching images data:', error);
      }
    }
  }
}
</script>

<style scoped></style>